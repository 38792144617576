import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import WorkerList from './pages/Worker/WorkerList'
import Recalculate from './pages/Recalculate/Recalculate'
import PerhitunganUpah from './pages/SalaryCalculation/SalaryCalculation'
import PemotonganUpah from './pages/SalaryCut/SalaryCut'
import UpahKeahlian from './pages/SalaryCutSkill/List'
import BonusPoint from './pages/BonusPoint/BonusPoint'
import UserLists from './pages/UserLists/UserLists'
import PriceSkill from './pages/PriceSkill/PriceSkill'
import Parameter from './pages/Parameter/Parameter'
import MasterBpjs from './pages/MasterBpjs/MasterBpjs'
import Deposit from './pages/Reports/DepositConsumer/Deposit'
import VendorCommission from './pages/Reports/VendorCommission/VendorCommission'
import SalaryCaltulationReport from './pages/Reports/SalaryCalculation/SalaryCalculation'
import DisbursmentWorkerReport from './pages/Reports/DisbursmentWorker/DisbursmentWorker'
import RefundContractorReport from './pages/Reports/RefundContractor/RefundContractorReport'
import BonusPointReport from './pages/Reports/BonusPoint/BonusPoint'
import WorkerRefund from './pages/WorkerRefund/WorkerRefundList'
import KasbonApproval from './pages/KasbonApproval/List'
import RefundCustomer from './pages/RefundCustomer/RefundCustomerList'
import RefundMaterial from './pages/RefundCustomer/RefundMaterial/RefundMaterialList'
import { useContext } from 'react'
import { AuthContext } from './context/AuthContext'
import './App.css'

function App() {
  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />
  }

  return (
    <div className="page">
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />

          <Route path="/">
            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="list-tukang"
              element={
                <RequireAuth>
                  <WorkerList />
                </RequireAuth>
              }
            />

            <Route
              path="recalculate"
              element={
                <RequireAuth>
                  <Recalculate />
                </RequireAuth>
              }
            />

            <Route
              path="point-bonus"
              element={
                <RequireAuth>
                  <BonusPoint />
                </RequireAuth>
              }
            />

            <Route
              path="perhitungan-upah"
              element={
                <RequireAuth>
                  <PerhitunganUpah />
                </RequireAuth>
              }
            />

            <Route path="upah">
              <Route
                path="pemotongan-upah"
                index
                element={
                  <RequireAuth>
                    <PemotonganUpah />
                  </RequireAuth>
                }
              />

              <Route
                path="permintaan-kasbon"
                index
                element={
                  <RequireAuth>
                    <KasbonApproval />
                  </RequireAuth>
                }
              />
              
              <Route
                path="upah-keahlian"
                element={
                  <RequireAuth>
                    <UpahKeahlian />
                  </RequireAuth>
                }
              />
            </Route>


            <Route path="maintenance">
              <Route
                path="user-lists"
                index
                element={
                  <RequireAuth>
                    <UserLists />
                  </RequireAuth>
                }
              />
              <Route
                path="harga-keahlian"
                element={
                  <RequireAuth>
                    <PriceSkill />
                  </RequireAuth>
                }
              />
              <Route
                path="parameter"
                element={
                  <RequireAuth>
                    <Parameter />
                  </RequireAuth>
                }
              />
              <Route
                path="bpjs"
                element={
                  <RequireAuth>
                    <MasterBpjs />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="reports">
              <Route
                path="deposit"
                index
                element={
                  <RequireAuth>
                    <Deposit />
                  </RequireAuth>
                }
              />
              <Route
                path="vendor-commission"
                element={
                  <RequireAuth>
                    <VendorCommission />
                  </RequireAuth>
                }
              />
              <Route
                path="upah-tukang"
                element={
                  <RequireAuth>
                    <SalaryCaltulationReport />
                  </RequireAuth>
                }
              />
              <Route
                path="disbursment-worker"
                element={
                  <RequireAuth>
                    <DisbursmentWorkerReport />
                  </RequireAuth>
                }
              />
              <Route
                path="refund-kontraktor"
                element={
                  <RequireAuth>
                    <RefundContractorReport />
                  </RequireAuth>
                }
              />
              <Route
                path="bonus_point"
                element={
                  <RequireAuth>
                    <BonusPointReport />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="penangguhan"
              element={
                <RequireAuth>
                  <WorkerRefund />
                </RequireAuth>
              }
            />
            <Route
              path="refund-customer"
              element={
                <RequireAuth>
                  <RefundCustomer />
                </RequireAuth>
              }
            />
            <Route
              path="refund-material"
              element={
                <RequireAuth>
                  <RefundMaterial />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
