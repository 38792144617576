import React, { useEffect, useState } from "react";
import Main from "../../../layouts/Main";
import Card from "../../../components/Card";
import styles from "./RefundMaterial.module.css";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import EditRefundMaterial from "./EditRefundMaterial";
import ConfirmRefundMaterial from "./ConfirmRefundMaterial";
import EmptyData from "../../../components/icons/EmptyData.jsx";

import RefundMaterialService from "../../../services/RefundMaterial";
import { convertToRupiah } from "../../../helper/RupiahDashboard";

function RefundMaterialList() {
  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isRefund, setIsRefund] = useState(null);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [dataRefund, setDataRefund] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [dataConfirm, setDataConfirm] = useState(null);

  const searchHandler = (event) => {
    const valueSearch = event.target.value;
    setSearch(valueSearch === "" ? null : valueSearch);
    setCurrentPage(1);
    if (event.key === "Enter") {
      getDataRefund();
    }
  };

  const clearSearch = () => {
    setSearch('');
    setCurrentPage(1);
    getDataRefund("resetSearch");
  };

  const changeRowPerPage = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
  };

  const changeStatusRefund = (event) => {
    const value = event.target.value;
    setIsRefund(value === "" ? null : value);
    setCurrentPage(1);
  };

  const changePageHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const showModalEdit = (data) => {
    setModalUpdate(true);
    setDataEdit(data);
  };

  const showModalConfirm = (data) => {
    setModalConfirm(true);
    setDataConfirm(data);
  };

  const closeModal = () => {
    setModalUpdate(false);
    setModalConfirm(false);
  };

  const getDataRefund = (type) => {
    RefundMaterialService.getRefundMaterial(
      currentPage,
      limit,
      type === "resetSearch" ? null : search,
      isRefund
    ).then((res) => {
      setDataRefund(res.data.data);
      setTotalPage(res.data.total_page);
    });
  };

  useEffect(() => {
    getDataRefund();
  }, [currentPage, limit]);

  return (
    <Main title="Refund Material">
      <Card col={"col-xl-12 col-lg-12"}>
        {/* Filter and Search */}
        <div className="row justify-content-between align-items-end mb-5">
          <div className="col-7">
            <p className="fw-bold">Status Bayar</p>
            <div>
              <select
                className={`${styles.selectStatus} form-select me-3`}
                onChange={changeStatusRefund}
                value={isRefund}
              >
                <option value="">Semua</option>
                <option value={0}>Belum Terbayar</option>
                <option value={1}>Terbayar</option>
              </select>
              <button className="btn btn-primary" onClick={getDataRefund}>
                Filter
              </button>
            </div>
          </div>
          <div className="col-5">
            <div className="form-group">
              <div className="input-group position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type keyword here.."
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  onChange={searchHandler}
                  onKeyDown={searchHandler}
                  value={search}
                />
                {search !== "" && search !== null && (
                  <button
                    type="button"
                    className={`btn btn-link text-decoration-none ${styles.clearSearch}`}
                    aria-label="Clear search"
                    onClick={clearSearch}
                  >
                    &#x2715;
                  </button>
                )}
                <button
                  className="btn btn-primary fa fa-search"
                  type="submit"
                  id="button-addon2"
                  onClick={getDataRefund}
                ></button>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="d-flex">
          <div className={styles.divTable}>
            <div className={styles.tableWrap}>
              <div className={styles.thead}>
                <div className={styles.trow}>
                  <div className={`text-center ${styles.tdata} ${styles.no}`}>
                    NO
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.projectId}`}
                  >
                    PROJECT ID
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.materialOrder}`}
                  >
                    MATERIAL <br /> ORDER ID
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.clientName}`}
                  >
                    CLIENT NAME
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.amount}`}
                  >
                    AMOUNT
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.feeAdmin}`}
                  >
                    FEE ADMIN
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.feeRefund}`}
                  >
                    FEE REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.percentage}`}
                  >
                    PERCENTAGE
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.totalRefund}`}
                  >
                    TOTAL REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.rekeningRefund}`}
                  >
                    REKENING REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.action}`}
                  >
                    ACTION
                  </div>
                </div>
              </div>
              <div className={styles.tbody}>
                {dataRefund.length > 0 ? (
                  dataRefund.map((item, index) => (
                    <div
                      className={`${styles.trow} ${
                        item.is_refund === 1 && styles.bgRefund
                      }`}
                    >
                      <div
                        className={`text-center ${styles.tdata} ${styles.no}`}
                      >
                        {limit * (currentPage - 1) + index + 1}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.projectId}`}
                      >
                        {item.project_id}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.materialOrder}`}
                      >
                        {item.material_order_id}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.clientName}`}
                      >
                        {item.client_name}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.amount}`}
                      >
                        Rp. {convertToRupiah(item.total_amount)}
                      </div>
                      <div
                        className={`text-center text-danger ${styles.tdata} ${styles.feeAdmin}`}
                      >
                        Rp. {convertToRupiah(item.admin_fee)}
                      </div>
                      <div
                        className={`text-center text-danger ${styles.tdata} ${styles.feeRefund}`}
                      >
                        Rp. {convertToRupiah(item.refund_fee)}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.percentage}`}
                      >
                        {item.percent}%
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.totalRefund}`}
                      >
                        Rp. {convertToRupiah(item.total_refund)}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${
                          styles.rekeningRefund
                        } ${item.is_refund === 1 && styles.bgRefund}`}
                      >
                        <div className={`${styles.dataRekening}`}>
                          <p className="m-0 text-nowrap">
                            {item.bank_name} {item.bank_name && "|"}{" "}
                            {item.bank_account_name}
                          </p>
                          <p className="m-0 pb-1">{item.bank_account_number}</p>
                        </div>
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${
                          styles.action
                        } ${item.is_refund === 1 && styles.bgRefund}`}
                      >
                        {item.is_refund === 1 ? (
                          <p className={`m-0 ${styles.paidText}`}>Terbayar</p>
                        ) : (
                          <React.Fragment>
                            <Button
                              button={`btn btn-sm me-3 ${styles.btnDetail}`}
                              icon="mdi mdi-eye text-white"
                              onClick={() => showModalEdit(item)}
                            />
                            <Button
                              button="btn-success btn-sm"
                              icon="fa fa-check"
                              onClick={() => showModalConfirm(item)}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`${styles.emptyData}`}>
                    <EmptyData className="ms-3" />
                    <p className="m-0 fw-bold">Tidak ada data ditemukan</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0">Row Per Page</p>
            <select
              className={`${styles.selectRowPerPage} form-select me-3`}
              onChange={changeRowPerPage}
              value={limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div>
          <Pagination
            pageCount={totalPage}
            changePage={changePageHandler}
            forcePage={currentPage - 1}
          />
        </div>
      </Card>

      {/* Modal Update */}
      {modalUpdate && (
        <EditRefundMaterial
          activeModal={modalUpdate}
          closeClick={closeModal}
          dataEdit={dataEdit}
          getDataRefund={getDataRefund}
        />
      )}

      {/* Modal Confirm */}
      {modalConfirm && (
        <ConfirmRefundMaterial
          activeModal={modalConfirm}
          closeClick={closeModal}
          dataConfirm={dataConfirm}
          getDataRefund={getDataRefund}
        />
      )}
    </Main>
  );
}

export default RefundMaterialList;
