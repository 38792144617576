import ReactPaginate from 'react-paginate'

const Pagination = (props) => {
  return (
    <ReactPaginate
      previousLabel={'Previous'}
      nextLabel={'Next'}
      pageCount={props.pageCount}
      onPageChange={props.changePage}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item page-prev"
      previousLinkClassName="page-link"
      nextClassName="page-item page-next"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination justify-content-center mt-5"
      activeClassName="active"
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      forcePage={props.forcePage}
    />
  )
}

export default Pagination
