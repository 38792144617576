import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";

import Modal from "../../../components/Modal";
// import { Toast } from "../../../config/Toast";

// import RefundMaterialService from "../../../services/RefundMaterial";
import { convertToRupiah } from "../../../helper/RupiahDashboard";

import styles from "./RefundMaterial.module.css";

function EditRefundMaterial(props) {
  const {
    activeModal = false,
    closeClick,
    dataEdit = null,
    // getDataRefund,
  } = props;

  const [totalRefund, setTotalRefund] = useState(0);
  // const notifySucces = (message) => toast.success(message, Toast);
  // const notifyFailed = (message) => toast.error(message, Toast);

  // const totalHandler = (event) => {
  //   const value = event.target.value;
  //   setTotalRefund(value);
  // };

  // const submitHandler = () => {
  //   const data = {
  //     project_id: dataEdit.project_id,
  //     total_refund: totalRefund,
  //   };

  //   RefundMaterialService.updateTotalRefundMaterial(
  //     dataEdit.material_refund_id,
  //     data
  //   )
  //     .then(() => {
  //       getDataRefund();
  //       notifySucces("Berhasil memperbahui data total refund");
  //       closeClick();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notifyFailed(
  //         err.response.data.message ||
  //           "Gagal memperbahui data total refund, silahkan coba kembali"
  //       );
  //       closeClick();
  //     });
  // };

  useEffect(() => {
    setTotalRefund(dataEdit.total_refund);
  }, [dataEdit]);

  return (
    <Modal
      activeModal={activeModal}
      closeClick={closeClick}
      // submitHandler={submitHandler}
      title="Detail Item Refund"
      // type="Simpan"
      modalType="modal-lg"
      modalFooter="false"
    >
      <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
        <thead>
          <tr>
            <th>NO</th>
            <th>MITRA</th>
            <th>MATERIAL</th>
            <th>QTY REFUND</th>
            <th>HARGA SATUAN</th>
            <th>TOTAL AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {dataEdit?.workers?.map((worker, index) => (
            <React.Fragment key={worker.worker_id}>
              {worker.materials.map((material, materialIndex) => (
                <tr key={material.material_id}>
                  {materialIndex === 0 && (
                    <td
                      className="align-middle"
                      rowSpan={worker.materials.length}
                    >
                      {index + 1}
                    </td>
                  )}
                  {materialIndex === 0 && (
                    <td
                      className="align-middle"
                      rowSpan={worker.materials.length}
                    >
                      {worker.worker_name}
                    </td>
                  )}
                  <td className="text-start">
                    <p
                      className={`fw-bold m-0 ${styles.textMaterial} ${
                        material.variant && material.variant_option && "mb-1"
                      }`}
                    >
                      {material.name}
                    </p>
                    {material.variant && material.variant_option && (
                      <p className={`m-0 ${styles.textVariant}`}>
                        {material.variant} : {material.variant_option}
                      </p>
                    )}
                    {material.subvariant && material.subvariant_option && (
                      <p className={`m-0 ${styles.textSubVariant}`}>
                        {material.subvariant} : {material.subvariant_option}
                      </p>
                    )}
                  </td>
                  <td className="align-middle">{material.qty_complained}</td>
                  <td className="align-middle">
                    Rp{convertToRupiah(material.price)}
                  </td>
                  <td className="align-middle">
                    Rp{convertToRupiah(material.total_complained_price)}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
          <tr>
            <td colSpan={5} className="text-end fw-bold text-center">
              Total
            </td>
            <td className="fw-bold">
              Rp{convertToRupiah(dataEdit.total_amount)}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="mt-3">
        <div className="row align-items-center">
          <p className="col-2 mt-4">Total Refund</p>
          <div className="input-group col">
            <input
              type="number"
              className="form-control"
              placeholder="0"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              min={0}
              onChange={totalHandler}
              value={totalRefund}
            />
          </div>
        </div>
      </div> */}
      <div className={`mt-6 ${styles.wrapButtonClose} d-flex align-items-center justify-content-end`}>
        <button
          className="btn btn-primary mt-4 me-4"
          onClick={closeClick}
        >
          Tutup
        </button>
      </div>
    </Modal>
  );
}

export default EditRefundMaterial;
