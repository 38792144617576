import axios from "axios";
import { headersTokenConfig } from "../config/HeaderToken";

class RefundMaterial {
  getRefundMaterial = (page, limit, search, isRefund) => {
    
    const endPoint = `/ticketing/refund-material?page=${page}&limit=${limit}`;
    let url = process.env.REACT_APP_API_URL_V1 + endPoint;

    if (search) {
      url = url + `&search=${search}`;
    }

    if (isRefund) {
      url = url + `&is_refund=${isRefund}`;
    }

    return axios.get(url, headersTokenConfig);
  };

  updateTotalRefundMaterial = (id, data) => {
    const url = process.env.REACT_APP_API_URL_V1 + `/ticketing/refund-material/${id}/update`;
    return axios.put(url, data, headersTokenConfig);
  };

  confirmRefundMaterial = (id, data) => {
    const url = process.env.REACT_APP_API_URL_V1 + `/ticketing/refund-material/${id}/confirm`;
    return axios.put(url, data, headersTokenConfig);
  };
}

export default new RefundMaterial();
